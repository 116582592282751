@import '../identity.scss';


.home {
    margin-top: 0;
}

#footer-links {
    margin-bottom: 2em;
}

#footer-links a {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

#compact-about-link {
    background-color: $highlight-fg;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin: 8px;
}

#compact-about-link a {
    color: $main-bg;
}

