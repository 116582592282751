#querier-reporter {
    padding-left: 6px;
    padding-right: 6px;
    position: fixed;
    top: 2px;
    left: 2px;
    border-radius: 4px;
}

.querier-reporter-normal {
    background-color: rgb(192, 192, 192);
}

.querier-reporter-waits img {
    height: 14px;
}

.querier-reporter-error {
    background-color: rgb(230, 116, 116);
    color: rgb(251, 221, 221);

}