@import 'identity.scss';
@import 'elements.scss';


#router-root {
  display: flex;
  flex-direction: column;
}

#main {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
}

#popup {
  box-sizing: border-box;
  position: absolute;
}

.highlight {
  color: $highlight-fg;
}

.link, .button {
  /** Same than a rule in elements.scss */
  text-decoration: none;
  color: $highlight-fg;
}

.link:hover, .button:hover {
  /** Same than a rule in elements.scss */
  text-decoration: underline;
}

.p-wrap {
  max-width: 40em;
  /** Add margin for non wide screens */
  margin-left: 3em;
  margin-right: 3em;
  line-height: 1.5em;
}

.clickable, .link {
  cursor: pointer;
}

#presentation-contrib {
  text-align: center;
}

.dangerous {
  color: #c70a0a;
}

.row {
  display: flex;
  flex-direction: row;
}

.centered-row{
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.column {
  display: flex;
  flex-direction: column;
}

.centered-column{
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.centered-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.path-on-bg {
  fill: $main-fg;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.fade-in {
  animation: fadeIn ease 1s;
  animation-timing-function: ease-in;
}

.warning {
  background-color: $warning-background;
  color: $warning-foreground;
}