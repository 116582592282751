@import "../identity.scss";

.full-logo {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.full-logo-link {
    /** Underline is ugly on this link. */
    text-decoration: none !important;
}

.logo-beta-tag {
    background-color: $c3a;
    margin-left: -30px;
    color: $main-bg;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 3px;
    text-transform: uppercase;
}