@import "../../identity.scss";

.search-home {
    box-sizing: border-box;
    width: 100vw;
    overflow: hidden;
}

.search-side {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.search-center {
    display: flex;
    flex-direction: column;
    transition-property: margin-top, height;
    margin-top: 0;
    transition-duration: 200ms;
    padding-bottom: 20px; /* Don't display content to close of the bottom of the screen */
    box-sizing: border-box; /* Since our size is responsively tdaking all the height, prevent the bottom padding from creating a scrollbar */
}

.search-home-large {
    margin-top: -200px;
}

.search-below-deck {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100vw;
}

#search-main-question {
    font-size: 1.5em;
    color: $main-bg;
}

