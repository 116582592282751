@import "../../../identity.scss";

.square-and-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.square-input-line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    /* Align the label and the input when they are on the same line (wide screens) */
    align-items: center;
}

.square-input {
    margin-left: 0.3em;
    margin-top: 5px;
    margin-bottom: 5px;
}

.square-input-line .share {
    margin-top: 0px;
}

.square {
    position: relative;
}

.square-idea {
    background-size: cover;
    background-position: center;
    position: absolute;
    transition-property: top, left, width, height, opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square-idea-highlight {
    box-sizing: border-box;
    transition-property: transform, opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    padding: 10%;
    background-color: rgba($color: #fff, $alpha: 0.6);
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: scale(1.6, 1.6);
    transform-origin: center;
    /* In small sques (TestUI) the text can overflow this div and trigger the hover outside the square. We prevent this by hidding overflow. */
    overflow: hidden;
}

.button-all .share {
    filter: brightness(100);
    margin-right: 2px;
    height: 12px;
}

.square-idea-highlight .button-all {
    margin-top: 10px;
}

.square-idea .square-idea-highlighted {
    transform: scale(1, 1);
    opacity: 1;
}

.square-idea-close-highlight {
    position: absolute;
    right: 10px;
    top: 10px;
}

.square-idea-title {
    font-size: 1.3em;
    color: #000;
    user-select: none;
}

.square-debug {
    position: absolute;
    top: 4px;
    left: 4px;
    max-width: 400px;
}

.square-debug-ideas {
    display: grid;
    grid-template-rows: repeat(3, 20px);
    grid-template-columns: repeat(3, 90px);
    gap: 2px;
}

.square-debug-idea {
    text-align: center;
    font-size: 0.7em;
    background-color: $main-darker;
}

.square-debug-idea a {
    color: $main-bg;
}

.square-debug-sql-detail-count {
    font-size: 0.6em;
}

.square-debug-sql-detail-count .sql-text{
    font-family: monospace;
}

.square-debug-answers {
    padding-left: 10px;
}

.square-debug-answers li {
    display: flex;
}

.square-debug-answers .question {
    margin-left: 5px;
}

.square-debug-answer-value {
    margin-left: 10px;
    margin-right: 10px;
}

.square-debug-answer-not-impacting {
    opacity: 0.4;
}

.square-debug-answer-impact {
    display: grid;
    grid-template-rows: repeat(3, 8px);
    grid-template-columns: repeat(3, 8px);
    font-size: 0.8em;
    font-family: monospace;
}

.square-debug-impact-0 {
    opacity: 0.4;
}

@media (max-width: 1000px) {
    .square-idea-highlighted {
        font-size: 0.75em;
    }
}

