@import '../../identity.scss';

#start-questions {
    flex: 1;
    padding-bottom: 20px; /* Don't display content to close of the bottom of the screen */
    box-sizing: border-box; /* Since our size is responsively taking all the height, prevent the bottom padding from creating a scrollbar */
}

.start-questions-line {
    margin: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.start-questions-submit-line {
    // Add space between the button and the line just above
    margin-top: 20px;
}

.start-questions-line label {
    margin-right: 1em;
    width: 10em;
    font-weight: bold;
}

.start-questions-input {
    width: 500px;
}

.search-start-hints-mono-step {
    width: 100%;
    height: 80px;
}

.search-start-hints-multi-step {
    height: 100px;
}

#start-questions-start-label:hover {
    color: $highlight-fg;
}

.start-questions-part {
    align-items: center;
}

.start-questions-part-input {
    width: 90vw;
    flex: 1;
    position: relative;
}

.sqpitf {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition-property: opacity;
    transition-duration: 800ms;
}

.sqpitf-visible {
    opacity: 1;

}

.sqpitf-hidden {
    opacity: 0;
    pointer-events: none;
}

.start-questions-waiting-gender {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.start-questions-has-not-chosen-gender {
    opacity: 0.2;
    filter: grayscale(100%);
}

@keyframes appear {
    from {
        opacity: 0.2;
        filter: grayscale(100%);
        }

    to {
        opacity: 1;
        filter: grayscale(0%);
    }
}

.start-questions-has-chosen-gender {
    animation-duration: 1s;
    animation-name: appear;
}

#search-start-low-age-explanation {
    height: 20px;
    color: $highlight-fg;
    max-width: 70vw;
}

.multi-part-start {
    width: 150px;
    font-size: 1.2em;
    margin-left: auto;
    margin-right: auto;
}