@import 'identity.scss';

/* Contains all rules applied directly to HTML elements */

body {
  margin: 0;
  padding: 0;
  font-family: $content-font;
  background-color: $main-bg;
  color: $main-fg;
}

h1, h2, h3 {
  color: $highlight-fg;
  font-weight: 500;
}

ul {
    list-style-type: none;
}

a {
  text-decoration: none;
  color: $highlight-fg;
}

a:hover {
  text-decoration: underline;
}