
.legal {
    max-width: 50em;
    text-align: left;
}

.legal em {
    font-style: normal;
    text-decoration: underline;
}

.legal 

.nohmargin {
    margin-top: 0;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .legal {
        font-size: 12px;
        padding-left: 1em;
        padding-right: 1em;
    }
}

@media (min-width: 1200px) {
}