.animated-placeholder-input {
    position: relative;
    overflow: hidden;
}

.animated-placeholder-input-placeholder{
    position: absolute;
    pointer-events: none;
    left: 5px;
    color: #888;
    /* Animate the opacity so that we don't see the item transitioning between end and begin. */
    transition-property: top, opacity;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
}


.animated-placeholder-input-placeholder-begin{
    top: -40px;
    opacity: 0;
}

.animated-placeholder-input-placeholder-mid{
    top: 0px;
    opacity: 1;
}

.animated-placeholder-input-placeholder-end{
    top: 40px;
    opacity: 0;
}