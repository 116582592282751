@import "../../../identity.scss";
@import "../../../zindexes.scss";

#square-share-dialog-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: $zIndexPopup;
    background-color: rgba($color: #000000, $alpha: 0.5);
}

#square-share-dialog {
    background-color: $main-bg;
    width: 600px;
    padding: 20px;
}

.square-share-close {
    float:right;
}

.square-share-input-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}


#square-share-url {
    flex: 1;
    margin-bottom: 10px;
}


@media (max-width: 800px) {

    #square-share-dialog {
        width: 80vw;
    }
    
    .square-share-input-row {
        flex-direction: column;
    }

    #square-share-url {
        width: 100%;
    }
}
