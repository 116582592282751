@import '../identity.scss';

footer {
    width: 100%;
    position: absolute;
    overflow: hidden;
    transition-property: top;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
}

footer svg {
    margin: 0;
}

.footer-separation-clouds {
    filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.5));
}

.footer-content {
    background-color: $c1;
    /* Fix the gap between this element and the clouds above */
    margin: -5px 0 0 0;
    padding: 10px;
}

footer, footer a, footer h2 {
    color: $main-bg;
}

footer a {
    text-decoration: underline;
}

footer p {
    max-width: 30em;
    margin: auto;
}