@import "./../identity.scss";

header {
    display: flex;
    justify-content:right;
    width: 100vw;
    position: absolute; /* Do not lose vertical space in the middle where we aren't */
}

#header-account-holder {
    border-bottom-left-radius: 5px;
    background-color: $darker-bg;
    padding-top:2px;
    padding-left: 10px;
}
