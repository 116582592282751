@import "../../identity.scss";


.ideas-highlight-header-small {
    font-size: 0.9em;
    background-color: $darker-bg2;
    padding: 15px;
    margin: 15px;
    border-radius: 10px;
    font-style: italic;
}

.ideas-highlight-header-large {
    font-size: 1.2em;
    padding: 10px;
    max-width: 450px;
    text-align: center;
    margin:auto;
}

.highlighted-idea {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.highlighted-idea img {
    border-radius: 4000px;
    object-fit: cover;
}

.highlighted-idea-title {
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
