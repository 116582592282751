@import '../../../identity.scss';

.share-button {
    border: 1px solid $c1;
    margin: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background-color: rgb(250, 250, 250); /* Same as WhatsApp background */
}

.share-button img {
    object-fit: contain;
}

.share-button span {
    /* Override the link color for Whatsapp */
    color: $c1;
}