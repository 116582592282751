@import '../../identity.scss';

#search-answers {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#search-answers .answer {
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 800px) {
    #search-answers .answer {
        margin-left: 3px;
        margin-right: 3px;
    }
    }

.answer-main {
    font-size: 22px;
}

.answer-secondary {
    font-size: 22px;
}

@media (max-width: 900px) {
    .answer-secondary {
        font-size: 20px;
    }
}

@media (max-width: 450px) {
    .answer-secondary {
        font-size: 16px;
    }
}

.search-answers-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}

$animationDuration: 500ms;

.answer-button-visible {
    opacity: 1;
    transition-property: opacity;
    transition-duration: $animationDuration;
    transition-delay: $animationDuration;
}

.answer-button-hidden {
    opacity: 0.1;
    transition-property: opacity;
    transition-duration: $animationDuration;
    pointer-events: none;
    transition-timing-function: ease-in;
}