.square-interrupt-header {
    font-size: 0.8em;
    margin: 15px;
    border-radius: 10px;
    font-style: italic;
    text-align: center;
}

.square-interrupt-footer {
    margin-top: 20px;
}