@import "../../identity.scss";

@mixin filled($color) {
    background-color: $color;
    color: $main-bg;
    border: 0.5px solid white;
    outline: 1.5px solid white;
    transition: 500ms filter linear;
}

@mixin not-filled($color) {
    border: 1px solid $color;
    color: $color;
    transition: 500ms filter linear;
    background-color: white;
    outline: 1.5px solid white;
}

.button-regular-size {
    padding: 7px 15.4px;
}

.button-small {
    padding: 4px 8.4px;
    font-size: 0.9em;
}

$box-shadow-color: #00266935;

.button-all {
    box-shadow: -6px 6px 0 -2px $box-shadow-color;
    position: relative;
    left: 0;
    top: 0;
    /** Margins for the drop shadows to not overlap other content, especially contiguous buttons **/
    margin-left: 4px;
    margin-right: 4px;
}

.link-button:hover {
    /* disable the link color override inside LinkButton. */
    text-decoration: none;
}

.button-all:active {
    left: -2px;
    top: 2px;
    transition-property: left, top, box-shadow;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
    box-shadow: -4px 4px 0 -2px $box-shadow-color;

}

$primary: $main-fg;
$positive: $c1;
$negative: $c3;
$neutral: $mid-c1-c3;

.button-primary { @include not-filled($primary); }
.button-primary-filled { @include filled($primary); }
.button-positive { @include not-filled($positive); }
.button-positive-filled { @include filled($positive); }
.button-negative { @include not-filled($negative); }
.button-negative-filled { @include filled($negative); }
.button-neutral { @include not-filled($neutral); }
.button-neutral-filled { @include filled($neutral); }
.button-dangerous { @include not-filled($dangerous); }
.button-dangerous-filled { @include filled($dangerous); }

.button-enabled {
    cursor: pointer;
}

.button-disabled {
    filter: grayscale(100%);
    -webkit-transition: 500ms -webkit-filter linear;
    -moz-transition: 500ms -moz-filter linear;
    -moz-transition: 500ms filter linear;
    -ms-transition: 500ms -ms-filter linear;
    -o-transition: 500ms -o-filter linear;
    transition: 500ms filter linear, 500ms -webkit-filter linear;
}

.buttons-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

.buttons-column .button-all{
    width: 100%;
    box-sizing: border-box;
    max-width: 500px;
    margin-top: 20px;
}

.buttons-row {
    display: flex;
    flex-direction: row;
}

.buttons-row .button-all .link-button{
    margin-left: 10px;
    margin-right: 10px;
}