// Don't forget to change identity.ts, manifest.json and email_manager.rs afterwards
$c1: #808eff;
$c2: #0069f2;
$c2a: #b8d5fb;
$c3: #30e0ff;
$c3a: #9af0ff;
$c4: #29ecb9;
$c4a: #acf8e4;

$c5: #cf8e50;
$mid-c1-c3: #58b6ff;

$c1-darker: #4e5dca;
$c3-lighter: rgb(240, 214, 65);
$c3-lighter2:rgb(250, 217, 156);


$warning-background: rgb(255, 228, 139);
$warning-foreground: rgb(235, 185, 20);
$dangerous: #bd0808;

$neutral: #ccc;
$main-bg: #fff;
$darker-bg: #e7f1ff;
$darker-bg2: #c5ddff;
$darker-bg3: #b8d5fb;
$main-fg: $c1-darker;
$main-darker: #04235a;
$highlight-fg: $c3;
$border: $darker-bg2;

@font-face {
    font-family: "Cabin";
    src: url("./fonts/Cabin-VariableFont_wdth\,wght.woff2") format("woff2");
}

@font-face {
    font-family: "Cabin";
    font-style: italic;
    src: url("./fonts/Cabin-Italic-VariableFont_wdth\,wght.woff2") format("woff2");
}

$content-font: 'Cabin', sans-serif;

