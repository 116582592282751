@import "../../identity.scss";

.card {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    pointer-events: auto;
}

.card .question-mark {
    color: $highlight-fg;
}

.card-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
}

.card-physical-border {
    stroke: rgb(224, 224, 224);
    fill: $main-bg;
}

.card-print-border {
    fill: none;
    stroke-width: 1;
    stroke-opacity:0.28000004;
    stroke:#0069f2;
}

.card-circle-bg {
    fill: $main-bg;
}

// Questions in card

.card-text {
    fill: $main-fg;
    font-size: 1.5em;
    z-index: 2;
}

// Ideas in card

.card-idea {
    z-index: 2;
    color: $main-fg;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.found-idea-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.found-idea-text-content {
    font-size: 1.5em;
}

.found-idea-title {
    margin-top: 10px;
    padding-bottom: 10px;
}

.found-idea-link-button {
    background-color: $main-fg;
    color: $main-bg;
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px;
    padding-right:10px;
    margin-bottom: 20px;
}