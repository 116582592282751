@import '../../../identity.scss';

.alert-creator {
    display: flex;
    flex-direction: column;
    width: 100vw;
    text-align: center;
    align-items: center;
}

.answers-sorter {
    position: relative;
    font-size: 0.9em;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
}

.answers-sorter .draggable {
    box-sizing: border-box;
    position: absolute;
    transition: top 100ms ease-in-out;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 100%;
}

.answers-sorter .draggable-answer {
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
    margin: 1px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $darker-bg;
    transition: background-color 500ms linear;
}

.answers-sorter .as-question-and-answer .question {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.answers-sorter .dragging-answer {
    background-color: $darker-bg2;
    transition: background-color 500ms linear;
}

.answers-sorter .answer {
    font-style: italic;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.answers-sorter .answer-alone {
    white-space: nowrap;
    color: $c2;
}

.answers-sorter-and-instructions .header-line {
    font-weight: bold;
}

.answers-sorter .ignore-line {
    font-weight: bold;
    color: #777;
    text-align: center;
}

.answer-ignored {
    filter: grayscale(100%);
    transition: filter 500ms linear;
}

.answer-not-ignored {
    transition: filter 500ms linear;
}

.alert-email-line {
    margin-top: 10px;
    margin-bottom: 10px;
}

.alert-email-line input {
    margin-left: 10px;
}

.alert-buttons-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.alert-buttons-line div {
    margin-left: 10px;
}

.alert-presentation-mono, .alert-submit-mono {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
}

.alert-sorter-mono {
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
}

.alert-error {
    position: absolute;
    background-color: $warning-background;
    color: $warning-foreground;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    top: 40vh;
}

.alert-error-visible {
    opacity: 0.95;
    transition: opacity 500ms linear;
}

.alert-error-hidden {
    opacity: 0;
    transition: opacity 500ms linear;
    pointer-events: none;
}