@import 'identity.scss';

// Sliders & Ranges
.rc-slider-mark-text {
    color: $main-fg;
}

.rc-slider-mark-text-active {
    color: $highlight-fg;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: $darker-bg;
}

.rc-slider-tooltip-inner {
    background-color: $darker-bg;
    color: $main-fg;
    box-shadow: 0 0 4px $darker-bg;
}

.rc-slider-track {
    background-color: $highlight-fg;
}

.rc-slider-handle {
    background-color: $highlight-fg;
    border-color: $highlight-fg;
}

// The small dots above markers which are insde the selected range
.rc-slider-dot-active {
    border-color: $highlight-fg;
}

// For touch screens make slider handle way larger
@media (min-resolution: 200dpi) {
    .rc-slider-handle {
        // 14px originally that we double
        width: 28px;
        height: 28px;
        // The margin top was 5, we add 7
        margin-top: - 12px;
    }

    .rc-slider-mark {
        top: 25px; // 18px originally and we added 14px to the handle so lower by 7px more
    }
}