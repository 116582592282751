.deck {
    perspective: 1600px;
    width: 100vw;
    overflow: hidden;
    position: relative;
    top: 0;
}

.transformable-card {
    transform-origin: center;
}

.below-card {
    position: absolute;
}