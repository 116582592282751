#about-root {
    flex: 1;
    max-width: 40em;
    margin: auto;
    line-height: 1.5em;
}

#about-root p {
    text-align: left;
}

.about-footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 5%;
}