@import '../identity.scss';

.gender-picker-root {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.gender-picker-line {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.gender-picker-entry {
    width: 6em;
}
