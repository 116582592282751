@import "../../identity.scss";
@import "../../index.scss";

.found-idea-link-button {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
}

/* Used instead of min() which is not yet widely supported https://caniuse.com/#search=min() */
@media (max-width: 460px) {
    .found-idea-link-button {
        max-width: 60vw;
    }
}

@media (min-width: 461px) {
    .found-idea-link-button {
        max-width: 280px;
    }
}

.found-idea-footer {
    @extend .centered-column;
    flex: 1;
    justify-content: space-evenly;
    animation-duration: 2s;
    animation-name: appear;
}

#found-idea-footer-text {
    margin-top: 1em;
    margin-bottom: 1em;
}

.found-idea-help {
    color: $mid-c1-c3;
}

.feedback-button-row {
    @extend .row;
    width: 90%;
    max-width: 500px;
    justify-content: space-evenly;
}

.feedback-button-row-secondary {
    @extend .feedback-button-row;
    font-size: 0.8em;
    justify-content: center;
}

.centered-button-row {
    @extend .row;
    width: 90%;
    max-width: 500px;
    justify-content: center;
}

/* LinkButtons are <a>, Buttons are <div> */
.centered-button-row > * {
    margin: 5px;
}

.idea-feedback-explanation {
    font-size: 0.9em;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}


@keyframes appear {
    from {
        opacity: 0;
        }

    to {
        opacity: 1;
    }
}
