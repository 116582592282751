@import "../../identity.scss";

#account-line {
    display: flex;
}

#account-line img {
    height: 16px;
    margin-top: 2px;
}

#account-logout {
    margin-left: 0.5em;
}
