@import "../identity.scss";

#service-worker-report {
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#service-worker-messages {
    background-color: $c3-lighter2;
    border-radius: 10px;
    color: $c5;
    padding: 4px 10px;
    margin: 5px;
    font-size: 0.8em;
}